import Presenter, { on, restore } from '~/js/core/base/Presenter';
import LaunchView from '~/js/views/controls/LaunchView';
import { CodeModel } from '~/js/models/common/CodeModel';
import { BoardModel } from '~/js/models/common/BoardModel';
import { BoardLayoutEvent } from '~/js/models/common/BoardModel/events';
import { ExerciseRunEvent } from '~/js/models/lesson/ProgressModel/events';

export default class AnalogResetLessonPresenter extends Presenter<LaunchView.LaunchView> {
  private code: CodeModel;
  private board: BoardModel;

  public getInitialProps() {
    this.code = this.getModel(CodeModel);
    this.board = this.getModel(BoardModel);
  }

  @restore()
  @on(ExerciseRunEvent)
  private onExerciseLoaded() {
    this.launchAnalogReset();
  }

  @on(BoardLayoutEvent)
  private onBoardLayout() {
    this.launchAnalogReset();
  }

  private launchAnalogReset() {
    const analogStates = this.board.getAnalogStatesInitial();

    if (!analogStates) {
      console.warn(
        'Arduino pin reset is postponed as board layout are not yet available',
      );
      return;
    }

    this.setViewProps({ is_executing: LaunchView.ButtonState.Busy });
    this.code.executeAnalogPinResetChain(analogStates);
  }
}
